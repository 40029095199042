export const theme = {
  colors: {
    bodyFont: "#000000",
    theHomeDepot: {
      primary: "#F66302",
      hover: "#C14C00",
      black: "#000000",
      link: "#C14C00",
    },
  },
  breakpoints: {
    mobileSm: "499px",
    mobileMd: "768px",
    tablet: "1072px",
    desktop: "1200px",
  },
}
